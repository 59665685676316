<template>
  <div class="box">
    <van-nav-bar
      :title="this.position"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <my-live-weather :lngLat="this.lngLat"></my-live-weather>
    <div class="archive">
      <div class="archive-title">{{ this.name }}</div>
      <div class="archive-image">
        <van-uploader multiple v-model="fileList" />
      </div>
      <div class="archive-content">
        <div class="archive-content-title">
          <div>{{ this.name }}</div>
          <div>{{ archivesFrom.crop_name }}</div>
          <div>{{ archivesFrom.count }}棵</div>
        </div>
        <div>定值:{{ archivesFrom.date }}</div>
        <div>树龄:{{ archivesFrom.age }}</div>
        <div style="display: flex; justify-content: space-between; width: 80%">
          <div>位置:{{ this.position }}</div>
          <div @click="toMap">查看地图</div>
        </div>
      </div>
    </div>
    <div class="archive-code">
      <div class="archive-code-title">
        <van-button plain size="small" type="primary" @click="loadMore(1)"
          >全部</van-button
        >
        <van-button plain size="small" type="primary" @click="loadMore(2)"
          >可售</van-button
        >
        <van-button plain size="small" type="primary" @click="loadMore(3)"
          >可订</van-button
        >
        <input type="text" v-model="code" placeholder="编号搜索" />
        <van-button
          plain
          size="small"
          type="primary"
          style="margin: 0"
          @click="loadMore(4)"
          >搜索编号</van-button
        >
      </div>
      <div class="archive-content">
        <div
          class="archive-content-list"
          v-for="(item, index) in count"
          :key="index"
          @click="toDetail(item.id)"
        >
          <div
            class="archive-content-div"
            :class="{
              aa: item.status === 'book',
              bb: item.status === 'notbook',
              blue: item.status == 'sale',
            }"
          >
            <div>{{ item.row_code}}-{{item.column_code}}</div>
            <div>{{ item.code }}</div>
            <div>{{ item.status_name }}</div>
          </div>
        </div>
        <div class="archive-content-button">
          <van-button
            type="primary"
            size="small"
            v-if="this.current < this.fileCurrent"
            @click="loadMore"
            >点击加载更多</van-button
          >
          <p v-else>没有更多数据了</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Uploader, Button } from "vant";
import MyLiveWeather from "../server/liveWeather.vue";
import {
  plantFileById,
  LandById,
  selectPageListAll
} from "../../service/archives/archivesDetails";
export default {
  data() {
    return {
      lngLat: {
        lat: "",
        lng: ""
      },
      position: "",
      archivesFrom: {},
      name: "",
      fileList: [],
      image: [],
      size: 10,
      current: 1,
      code: "",
      status: "",
      count: [],
      plantFileId: "",
      fileCurrent: 0,
      longitude: "",
      latitude: ""
    };
  },
  components: {
    "van-nav-bar": NavBar,
    VanUploader: Uploader,
    VanButton: Button,
    "my-live-weather": MyLiveWeather
  },
//   移除页面滚动监听事件
  methods: {
    async init() {
      const id = this.$route.params && this.$route.params.id;
      const res = await plantFileById(id);
      this.plantFileId = res.data.id;
      this.getSelectPageListAll(this.plantFileId);
      this.getLandById(res.data.land_id);
      this.archivesFrom = res.data;
      this.image = res.data.imageUrl;
      var isimage = true;
      for (var i = 0; i < this.image.length; i++) {
        var url = this.image[i];
        this.fileList.push({ isimage, url });
      }
    },
    async getLandById(id) {
      const res = await LandById(id);
      this.lngLat = {
        lat: res.data.land.latitude,
        lng: res.data.land.longitude
      };
      console.log(this.lngLat);
      this.longitude = res.data.land.longitude;
      this.latitude = res.data.land.latitude;
      this.position = `${res.data.land.province_name}-${res.data.land.city_name}-${res.data.land.county_name}`;
      this.name = res.data.land.name;
    },
    // 拿到可售可订的数据
    async getSelectPageListAll(id) {
      const res = await selectPageListAll(
        id,
        this.current,
        this.size,
        this.code,
        this.status
      );
      this.count = res.data.records;
    },
    // 点击返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 点击加载数据
    async loadMore(sell) {
      this.current += 1;
      if (sell === 1) {
        this.current = 1;
        this.status = "";
        this.count = "";
      } else if (sell === 2) {
        this.current = 1;
        this.status = "sale";
        this.count = "";
      } else if (sell === 3) {
        this.current = 1;
        this.status = "book";
        this.count = "";
      } else if (sell === 4) {
        this.current = 1;
        this.status = "";
        this.count = "";
      }
      const res = await selectPageListAll(
        this.plantFileId,
        this.current,
        this.size,
        this.code,
        this.status
      );
      this.count = this.count.concat(res.data.records)
      this.fileCurrent = Number(res.data.pages);
    },
    toDetail(id) {
      this.$router.push({
        name: "PlantingDetails",
        params: {
          id: id,
          name: this.name
        }
      });
    },
    toMap() {
      this.$router.push({
        name: "ArchivesMap",
        params: {
          latitude: this.latitude,
          longitude: this.longitude,
          name: this.name,
          position: this.position
        }
      });
    }
  },
  mounted() {
    this.init();
    this.loadMore();
  }
};
</script>

<style lang="scss" scoped>
.archive {
  margin: 1vh 3vw;
  div {
    margin-top: 1vh;
  }
  .archive-title {
    text-align: center;
    margin-top: 2vh;
    color: #000;
    font-size: 2.5vh;
    font-weight: 400;
  }
  .archive-image {
    height: 20vh;
    display: flex;
    align-items: center;
  }
  .archive-content-title {
    width: 80vw;
    display: flex;
    justify-content: space-between;
  }
}
.archive-code {
  margin: 1vh 2vw;
  .archive-code-title {
    .van-button {
      margin-left: 10px;
    }
    input {
      width: 100px;
      margin-left: 7vw;
      padding-bottom: 1.5vh;
    }
  }
  .archive-content {
    display: flex;
    margin-top: 2vh;
    flex-wrap: wrap;
    .archive-content-list {
      .archive-content-div {
        border-radius: 2vw;
        margin: 0.5vh 2vw;
        width: 15vw;
        text-align: center;
      }
    }
    .archive-content-button {
      width: 100vw;
      text-align: center;
      margin-top: 1vh;
    }
  }
  .aa {
    background: #68ee68;
  }
  .bb {
    background: #e8e8e8;
  }
  .blue {
    background: skyblue;
  }
}
</style>
