import server from "../axios.config.js";

export const getLivingWeather = function(lng, lat) {
    return server({
        method: "get",
        baseURL: "http://110.87.103.59:19090/",
        url: `live/weather/getliveweather/${lng}/${lat}`
    });
};

// 地块详情 byId
export const getFieldById = function(id) {
    return server({
        method: "get",
        baseURL: "http://110.87.103.59:18086/farmfriend",
        url: "/land/getById",
        params: {
            id
        }
    })
}

// 地块作物
export const getFieldByIds = function(cropId, landId) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/farmfriend/landCrop/selectLandCropListNew?landId=${landId}&cropId=${cropId}`,
        params: {
            landId
        }
    })
}

/****
 * 土壤检测接口
 * landId: 农田的id
 * ****/

export const getSoildetect = function(landId) {
    return server({
        method: "get",
        baseURL: "http://110.87.103.59:18086/farmfriend",
        url: "/soildetect/getList",
        params: {
            landId
        }
    })
}