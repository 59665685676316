<template>
  <div>
    <template v-if="Object.keys(weatherData).length === 0">
      <div
        style="height: 40px; background: #0094ff; display: flex; justify-content: center;"
      >
        <van-loading color="#fff" size="40">实况数据加载中...</van-loading>
      </div>
    </template>
    <template v-else>
      <div class="live-weather">
        <div class="time">
          <span style="margin-right: 6px">{{ formTime }}</span>
          <span>{{ formChinaTime }}</span>
        </div>
        <div class="huidity">
          <span>湿度</span>
          <span>{{ weatherData.hum }}%</span>
        </div>
        <div class="wind">
          <span>{{ weatherData.wd === "9999" ? "-" : weatherData.wd }}</span>
          <span> {{ weatherData.ws === "9999" ? "-" : weatherData.ws }}</span>
        </div>
        <div class="weather">
          <span>{{ weatherData.temp }}℃</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { Loading } from "vant";
import { getLivingWeather } from "@/service/serverPage/fieldDetail.js";
export default {
  name: "LiveWeather", // 服务-头部-实况天气
  components: {
    "van-loading": Loading
  },
  props: ["lngLat"],
  data() {
    return {
      weatherData: {}
    };
  },
  watch: {
    lngLat: function(val) {
      if (val.lng && val.lat) {
        getLivingWeather(this.lngLat.lng, this.lngLat.lat).then(res => {
          this.weatherData = res.data.liveandate;
        });
      }
    }
  },
  computed: {
    formTime: function() {
      return dayjs(this.weatherData.date).format("MM-DD");
    },
    formChinaTime: function() {
      return this.weatherData.cntime && this.weatherData.cntime.slice(2);
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.live-weather {
  display: flex;
  background: #001f90;
  color: #fff;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding:0 6px 10px;

  .weather {
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
